import type {
    AttributeDefaultValue,
    AttributeType,
    ChildFallback,
    ChildSelector,
    IPropsChildren,
} from './ui-element.types.js';
import type { UIElement } from './ui-element.js';
import { ComponentState } from './ui-element.const.js';

/*
 * Get and set attributes based on properties.
 * TODO GUI-2934 Reflection
 */
export const resolvePropertyDescriptor = <
    T extends UIElement,
    U extends AttributeType,
>(
    name: string,
    type: AttributeDefaultValue<U>,
    defaultValue?: AttributeDefaultValue<U>
) => {
    let descriptor: PropertyDescriptor;
    switch (type) {
        /**
         * Getter:
         * This deals with objects/arrays, that are meant to be passed in via template literals or Angular.
         * "_..." in the attribute name is used in order to avoid infinite recursive getter calls.
         * Setter:
         * Shallow equality is checked, so inorder to trigger the elements observedAttributes callback,
         * new object or array should be created with the updated structure.
         */
        case 'object':
        case Object:
            descriptor = {
                get() {
                    return (
                        (<T>this)[Symbol.for(name) as keyof T] || defaultValue
                    );
                },
                set(value) {
                    const oldValue = (<T>this)[Symbol.for(name) as keyof T];
                    if (oldValue !== value) {
                        (<T>this)[Symbol.for(name) as keyof T] = value;
                        if (
                            (<typeof UIElement>(
                                this.constructor
                            )).observedAttributes.includes(name)
                        ) {
                            (<T>this).observeAttributes(
                                name,
                                <AttributeType>oldValue,
                                value
                            );
                        }
                    }
                },
            };
            break;
        /**
         * Getter:
         * If no attribute attached to the element then the property value is false,
         * If the attribute equals either empty string (attribute without value)
         * or itself the property value is true
         * Otherwise it checks content using the 'parseBooleanAttr' logic.
         * Setter:
         * If value is true it will add an empty attribute
         * If value is false it will remove the attribute
         */
        case 'boolean':
        case Boolean:
            descriptor = {
                get() {
                    if (!(<T>this).hasAttribute(name)) {
                        return defaultValue || false;
                    }
                    const value = (<T>this).getAttribute(name);
                    return (
                        value === '' ||
                        value === name ||
                        (<T>this).parseBooleanAttr(<string>value)
                    );
                },
                set(value) {
                    if (value) {
                        (<T>this).setAttribute(name, '');
                    } else {
                        if (defaultValue === true) {
                            (<T>this).setAttribute(name, 'false');
                        } else {
                            (<T>this).removeAttribute(name);
                        }
                    }
                },
            };
            break;

        /**
         * Getter:
         * Gets number value from the attribute, if attribute is not exist
         * the default value will be returned (if presented, otherwise - null)
         * Setter:
         * Sets string representation of number value to the attribute
         */
        case 'number':
        case Number:
            descriptor = {
                get() {
                    const value = (<T>this).getAttribute(name);
                    if (value !== null || defaultValue === undefined) {
                        return parseFloat(`${value || 0}`);
                    }
                    return defaultValue;
                },
                set(value) {
                    (<T>this).setAttribute(name, String(value));
                },
            };
            break;

        case 'string':
        case String:
        default:
            /**
             * Getter:
             * Gets string value from the attribute, if attribute is not exist
             * the default value will be returned (if presented, otherwise - null)
             * Setter:
             * Converts any value to string and sets it to the attribute
             */
            descriptor = {
                get() {
                    const value = (<T>this).getAttribute(name);
                    if (value !== null || defaultValue === undefined) {
                        return value;
                    }
                    return defaultValue;
                },
                set(value) {
                    if (value === null) {
                        (<T>this).removeAttribute(name);
                    } else {
                        (<T>this).setAttribute(name, value);
                    }
                },
            };
    }
    descriptor.configurable = true;
    return descriptor;
};

export const resolveChildDescriptor = <T extends UIElement>(
    propertyKey: string,
    selector: ChildSelector,
    fallback: ChildFallback,
    multiple?: boolean
) => {
    const sym = Symbol.for(propertyKey);
    return {
        get(this: T & { [key: symbol]: IPropsChildren }) {
            const target =
                this[sym] || !!multiple
                    ? this.querySelectorAll(<string>selector)
                    : this.querySelector(<string>selector);
            if (
                [ComponentState.Rendered, ComponentState.Hydrated].indexOf(
                    this.state
                ) > -1 ||
                !fallback
            ) {
                return target;
            }
            return fallback.bind(this)();
        },
        set(this: { [key: symbol]: IPropsChildren }, value: IPropsChildren) {
            this[sym] = value;
        },
        configurable: true,
    };
};

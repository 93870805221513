import { Labels } from '../global/labels.js';
import {
    nextByCond,
    previousByCond,
    closestByCond,
    closestEventHolder,
    closestTag,
    createElement,
    defineElement,
    detachChildNodes,
    dispatchCustomEvent,
    dispatchNativeEvent,
    focusInteractiveElement,
    getAttributes,
    getChildrenForSlot,
    getFocusableElements,
    hide,
    insertElements,
    isDOMReady,
    isParentOf,
    isVisible,
    mergeAttributes,
    position,
    queryChildren,
    redetachChildNodes,
    revertClassList,
    runTransition,
    setAttributes,
    setInnerText,
    show,
    updateClassList,
    updateElement,
    whenDOMReady,
    mutateAttributesTo,
    mutateChildrenTo,
    mutateTo,
    prerenderElementsTree,
    rebuild,
    rebuildChildren,
    runSmoothRemove,
    runSmoothAppend,
    tagRegistry,
    setDebugMode,
    getDebugMode,
    getRenderMode,
    RenderMode,
    setRenderMode,
    nameConverter,
} from '../global/ui-helpers.js';
import type {
    AttributeDefaultValue,
    AttributeType,
    IProps,
    ChildFallback,
    ChildSelector,
    Predicate,
    IElementConfig,
    LifeCycleHook,
    EventHandler,
    AttributeValidationHandler,
    TLabels,
    IComponentPlugin,
    IPropsAttribute,
    ComponentStyles,
    Locale,
} from './ui-element.types.js';
import {
    resolveChildDescriptor,
    resolvePropertyDescriptor,
} from './ui-element.helper.js';
import { ComponentState } from './ui-element.const.js';
import { Attribute } from '../decorators/attribute.decorator.js';
import { Deprecated } from '../decorators/deprecated.decorator.js';

/**
 * @memberof SharedComponents
 * @augments {HTMLElement}
 * @alias UIElement
 * @element ui-element
 * @classdesc Core interface for classes that represent a Custom Element.
 * Don't use this element, this is only for testing purposes mostly.
 * This can be used as "smart" div, if you know exactly what are you doing.
 * Methods of this class are inherited by all other UI components.<br>
 *   <ui-message type="urgent">
 *     You should never change state attribute <b>state=""</b> manually!
 *   </ui-message>
 *   <ui-message type="warning">
 *     In the most cases you should not create <b>&lt;ui-element></b>.
 *   </ui-message>
 * @property {boolean} rendered {@readonly} Shows if the component is in rendered state.
 * @property {boolean} hydrated {@readonly} Shows if the component is in hydrated state.
 * @property {string} plugins {@attr plugins} Space-separated plugins for component.
 * @property {IProps} props - Component attribute, children bindings.
 * True for all components at this version.
 */
// By unknown reason here it doesn't generate docs.
// The most probably JSDoc BUG
// FIXME GUI-3101, should work after migration from JSDoc.
// @Component({ tag: 'ui-element' })
class UIElement extends HTMLElement {
    protected subscriptions: Record<string, Record<string, unknown[]>> = {}; // Deprecated
    protected static labels: TLabels = {};
    protected static plugins: Record<string, IComponentPlugin>;
    protected static autoloadPlugins: Record<string, IComponentPlugin>;
    protected instancePlugins!: Record<string, IComponentPlugin>;
    static observedAttributes: string[] = [];
    static props: IProps = {
        attributes: {},
        children: {},
    };
    private static propsToValidate: string[] = [];

    @Attribute(String) state!: ComponentState;

    /**
     * Locale for UIElements. Possible values are: en, ru, ee, lv, lt.
     * @type {Locale}
     */
    static get LOCALE() {
        return <Locale>Labels.LOCALE;
    }

    static set LOCALE(value: Locale) {
        Labels.LOCALE = value;
    }

    /**
     * Debug mode, default is false.
     * @type {boolean}
     */
    @Deprecated(`Use config.getDebugMode()`)
    static get DEBUG_MODE() {
        return getDebugMode();
    }

    /**
     * @param {boolean} value
     */
    @Deprecated(`Use config.setDebugMode(true)`)
    static set DEBUG_MODE(value: boolean) {
        setDebugMode(value);
    }

    /**
     * Rendering mode, default is synchronous.
     * @type {boolean}
     */
    static get ASYNC_MODE() {
        return getRenderMode() === RenderMode.Asynchronous;
    }

    /**
     * @param {boolean} value
     */
    @Deprecated(`Use config.setRenderMode(RenderMode.Asynchronous);`)
    static set ASYNC_MODE(value) {
        setRenderMode(value ? RenderMode.Asynchronous : RenderMode.Synchronous);
    }

    /**
     * Language for UIElements. Possible values are: en, et, lv, lt, ru.
     * @type {string}
     */
    static get LANGUAGE() {
        return Labels.LANGUAGE;
    }

    static set LANGUAGE(value) {
        Labels.LANGUAGE = value;
    }

    /**
     * Checks if document is ready for Web Components to be rendered.
     * @deprecated use UI.isDOMReady()
     * @returns {boolean}
     */
    static get isDOMReady() {
        return isDOMReady;
    }

    /**
     * Adds DOMLoaded handler.
     * @returns {*}
     */
    @Deprecated(`Use UI.whenDOMReady()`)
    static get whenDOMReady() {
        return whenDOMReady;
    }

    /**
     * Defines attribute on component construct.
     * @param {string} name
     * @param {IAttributeType} [type]
     * @param {string | number | boolean} [defaultValue]
     * @param {string} [propertyKey]
     * @returns {UIElement}
     */
    static defineAttribute(
        name: string,
        type: AttributeType,
        defaultValue?: AttributeDefaultValue<AttributeType>,
        propertyKey?: string
    ) {
        propertyKey = <string>nameConverter.attrToProperty(propertyKey || name);
        const descriptor = resolvePropertyDescriptor(name, type, defaultValue);
        Object.defineProperty(this.prototype, propertyKey, descriptor);
        return this;
    }

    /**
     * Defines child shortcut on component construct.
     * @param {string} propertyKey - name of property
     * @param {string} selector
     * @param {Function} [fallback] - fallback for case if element is not yet rendered
     * @param {boolean} [multiple] - targeting multiple nodes.
     * @returns {UIElement}
     */
    static defineChild(
        propertyKey: string,
        selector: ChildSelector,
        fallback: ChildFallback,
        multiple: boolean = false
    ) {
        const descriptor = resolveChildDescriptor(
            propertyKey,
            selector,
            fallback,
            multiple
        );
        Object.defineProperty(this.prototype, propertyKey, descriptor);
        return this;
    }

    /**
     * Defines children shortcut on component construct.
     * @param {string} propertyName - name of property
     * @param {string} selector
     * @param {Function} [fallback] - fallback for case if element is not yet rendered
     * @returns {UIElement}
     */
    @Deprecated(`This method is strongly deprecated and exists only for backwards
     compatibility of the long time ago create project. You should not ever use it,
     it will be removed! Define children in props instead.`)
    static defineChildren(
        propertyName: string,
        selector: ChildSelector,
        fallback: ChildFallback
    ) {
        return this.defineChild(propertyName, selector, fallback, true);
    }

    /**
     * Defines abstract element.
     * Used for creating abstract elements that can be used as a base for other elements.
     * @param {IProps} [props] Define properties for the element.
     * @returns {UIElement}
     */
    static defineAbstractElement(props: IProps = this.props) {
        if (typeof props === 'object') {
            const attributes = (props || this.props).attributes || {};
            const children = (props || this.props).children || {};
            for (const key in attributes) {
                if (Object.hasOwn(attributes, key)) {
                    const attribute = attributes[key];
                    let name = nameConverter.propToAttribute(key);
                    let type: AttributeType;
                    let defaultValue: AttributeDefaultValue<AttributeType> =
                        null;
                    let validate = null;
                    if (typeof attribute === 'object' && attribute !== null) {
                        if (attribute.name) {
                            name = attribute.name;
                        }
                        type = <AttributeType>attribute.type;
                        defaultValue = <AttributeDefaultValue<AttributeType>>(
                            (attribute.default !== undefined
                                ? attribute.default
                                : defaultValue)
                        );
                        validate = attribute.validate;
                    } else {
                        type = attribute;
                    }
                    if (validate) {
                        this.propsToValidate.push(name);
                    }
                    this.defineAttribute(name, type, defaultValue, key);
                }
            }
            for (const key in children) {
                if (Object.hasOwn(children, key)) {
                    const child = children[key];
                    let selector: ChildSelector = null;
                    let fallback: ChildFallback = null;
                    let multiple = false;
                    if (typeof child === 'object' && child !== null) {
                        selector = child.selector as ChildSelector;
                        fallback = child.fallback as ChildFallback;
                        multiple = !!child.multiple;
                    } else {
                        selector = child;
                    }
                    this.defineChild(key, selector, fallback, multiple);
                }
            }
        }
        return this;
    }

    /**
     * Defines custom element
     * @param {string} tag
     * @param {string[] | string} [styles]
     * @returns {UIElement}
     */
    static defineElement(tag: string, styles?: ComponentStyles) {
        this.defineAbstractElement();
        const placement = document.getElementById('shared-components-style');
        defineElement(tag, this, { styles: styles, stylePlacement: placement });
        return this;
    }

    /**
     * Defines property on component construct.
     * @param {string | symbol} propertyName - name of property
     * @param {PropertyDescriptor} descriptor
     * @returns {UIElement}
     */
    @Deprecated(`This method is strongly deprecated and exists only for backwards
     compatibility of the long time ago create project. You should not ever use it,
     it will be removed! Define properties in props instead.`)
    static defineProperty(
        propertyName: string | symbol,
        descriptor: PropertyDescriptor
    ) {
        Object.defineProperty(this.prototype, propertyName, descriptor);
        return this;
    }

    /**
     * Merge two attributes objects.
     * @returns {IAttributeProps | NamedNodeMap}
     */
    @Deprecated(`Use UI.mergeAttributes()`)
    static get mergeAttributes() {
        return mergeAttributes;
    }

    /**
     * Smoothly remove node from DOM tree.
     * @returns {Promise<void>}
     */
    @Deprecated(`Use UI.runSmoothRemove()`)
    static get runSmoothRemove() {
        return runSmoothRemove;
    }

    /**
     * Smoothly append the NODE to target element.
     * @returns {Promise<void>}
     */
    @Deprecated(`Use UI.runSmoothAppend()`)
    static get runSmoothAppend() {
        return runSmoothAppend;
    }

    /**
     * Mutates children object.
     * @returns {Function}
     */
    @Deprecated(`Use UI.mutateChildrenTo()`)
    static get mutateChildrenTo() {
        return mutateChildrenTo;
    }

    /**
     * Mutates attributes object.
     * @returns {Function}
     */
    @Deprecated(`Use UI.mutateAttributesTo()`)
    static get mutateAttributesTo() {
        return mutateAttributesTo;
    }

    /**
     * It will create a path to mutate old node element to new node.
     * @returns {Function}
     */
    @Deprecated(`Use UI.mutateTo()`)
    static get mutateTo() {
        return mutateTo;
    }

    /**
     * Gets element tag name.
     * @returns {string}
     */
    static get is() {
        return tagRegistry.get(this);
    }

    get rendered() {
        return [ComponentState.Rendered, ComponentState.Hydrated].includes(
            this.state
        );
    }

    get hydrated() {
        return this.state === ComponentState.Hydrated;
    }

    /**
     * Checks if the element is parent of given node.
     * @param {Element} node - given node
     * @returns {boolean} - true if current node is parent to given node
     */
    isParentOf(node: Element) {
        return isParentOf(this, node);
    }

    /**
     * Checks if the element is visible.
     * @returns {boolean} true if visible
     */
    isVisible() {
        return isVisible(this);
    }

    /**
     * Converts value to attribute.
     * @param {boolean} value
     * @returns {string}
     */
    convertBooleanAttr(value: boolean) {
        return value ? 'true' : 'false';
    }

    /**
     * Parses attribute's boolean value.
     * @param {string} value
     * @returns {boolean}
     */
    parseBooleanAttr(value: string) {
        return Boolean(value === 'yes' || value === 'true' || value === '1');
    }

    /**
     * Returns list of subscriptions for given target and event name.
     * @private
     * @param {string} target - child selector or :root by default for self
     * @param {string} eventName - the event name
     * @returns {Array<Function>} list of callbacks are bond to the event
     */
    @Deprecated()
    private getSubscriptions(target: string, eventName: string) {
        const key = !target ? ':root' : target;
        if (!this.subscriptions[key]) {
            this.subscriptions[key] = {};
        }
        if (!this.subscriptions[key][eventName]) {
            this.subscriptions[key][eventName] = [];
        }
        return this.subscriptions[key][eventName];
    }

    /**
     * return list of the node's attributes.
     * @returns {Record<string, string | null>}
     */
    getAttributes() {
        return getAttributes(this);
    }

    /**
     * @param {Record<string, string | null>} attributes to be applied to the node
     * @example
     * // Set attributes "foo" and "bar" to attributes "id" and "name" respectively
     * element.setAttributes({id: "foo", name: "bar"});
     * @returns {UIElement | HTMLElement}
     */
    setAttributes(
        attributes: Record<string, AttributeDefaultValue<AttributeType>>
    ) {
        return setAttributes(this, attributes);
    }

    /**
     * Remove class -hidden to show the element.
     * @returns {UIElement | HTMLElement}
     */
    show() {
        return show(this);
    }

    /**
     * Add class -hidden to hide the element.
     * @returns {UIElement | HTMLElement}
     */
    hide() {
        return hide(this);
    }

    /**
     * Focus first interactive element inside of the node.
     * @param {boolean} skipPriorities
     * @returns {UIElement | Element}
     */
    focusInteractiveElement(skipPriorities: boolean) {
        return focusInteractiveElement(this, skipPriorities);
    }

    /**
     * Return list of focusable elements
     * @returns {Array<HTMLElement> | *}
     */
    getFocusableElements() {
        return getFocusableElements(this);
    }

    /**
     * Search the closest parent node matched by given predicate.
     * @example
     * // Search closest parent node with "id" equals "foo"
     * element.closestByCond(function (node) {
     *     return node.id === "id"
     * });
     * @param {Function} searchPredicate - the callback should return true value if node is matched
     * @param {Function} [stopPredicate] - if the callback returns true than search will be stopped
     * @returns { * | HTMLElement }
     */
    closestByCond(searchPredicate: Predicate, stopPredicate: Predicate) {
        return closestByCond(this, searchPredicate, stopPredicate);
    }

    nextByCond(searchPredicate: Predicate) {
        return nextByCond(this, searchPredicate);
    }

    previousByCond(searchPredicate: Predicate) {
        return previousByCond(this, searchPredicate);
    }

    /**
     * @ignore
     */
    @Deprecated(`Use HTMLElement.closest()`)
    closestTag(tagName: string, stopNode: Node) {
        return closestTag(this, tagName, stopNode);
    }

    closestEventHolder(stopNode: Node) {
        return closestEventHolder(this, stopNode);
    }

    /**
     * @param {string} text
     * @param {boolean} [trusted] - defines if the text is trusted HTML
     * @returns {UIElement | HTMLElement}
     */
    setInnerText(text: string, trusted?: boolean) {
        return setInnerText(this, text, trusted);
    }

    /**
     * Creates element by given tagName and config.
     * @param {IElementConfig} config
     * @param {boolean} [trusted] - defines if element's strings are trusted HTML
     * @returns {* | HTMLElement}
     */
    createElement(config: IElementConfig, trusted?: boolean) {
        return createElement(config, trusted);
    }

    /**
     * Updates element by given config.
     * @param {IElementConfig} config
     * @param {boolean} [trusted] - defines if element's strings are trusted HTML
     * @returns {* | HTMLElement}
     */
    updateElement(config: IElementConfig, trusted?: boolean) {
        return updateElement(this, config, trusted);
    }

    /**
     * Inserts elements inside component.
     * @param {Array<IElementConfig | HTMLElement>} configs
     * @param {InsertPosition} [position]
     * @param {boolean} [trusted] - defines if element's strings are trusted HTML
     * @returns {HTMLElement}
     */
    insertElements(
        configs: (IElementConfig | HTMLElement)[],
        position?: InsertPosition,
        trusted?: boolean
    ) {
        return insertElements(this, configs, position, trusted);
    }

    /**
     * Dispatches custom event.
     * @param {string} name - the event name
     * @param {*} [detail] - the event data object or value
     * @param {boolean} [bubbles] - enable event bubbling. Enabled by default
     * @param {boolean} [cancelable] - enable preventing default action. Enabled by default
     * @returns {CustomEvent}
     */
    dispatchCustomEvent(
        name: string,
        detail?: Record<string, unknown>,
        bubbles?: boolean,
        cancelable?: boolean
    ) {
        return dispatchCustomEvent(this, name, detail, bubbles, cancelable);
    }

    /**
     * Dispatches native event.
     * @param {string} name - the event name
     * @param {boolean} [bubbles] - enable event bubbling. Enabled by default
     * @returns {Event}
     */
    dispatchNativeEvent(name: string, bubbles?: boolean) {
        return dispatchNativeEvent(this, name, bubbles);
    }

    /**
     * @param {string} key
     * @returns {Array<HTMLElement>}
     * @protected
     */
    protected getChildrenForSlot(key: string) {
        return getChildrenForSlot(this, key);
    }

    /**
     * @returns {Array<HTMLElement>}
     * @protected
     */
    protected detachChildNodes() {
        return detachChildNodes(this);
    }

    /**
     * HTML parser needs to know the children's data.
     * So we will re-detach them to make browser renders them first.
     * @protected
     */
    protected redetachChildNodes() {
        redetachChildNodes(this);
    }

    /**
     * Finds direct children matched by condition.
     * @param {string} selector
     * @returns {HTMLElement[]}
     */
    queryChildren(selector: string) {
        return queryChildren(this, selector);
    }

    /**
     * Returns the node's position in the parent DOM tree.
     * @returns {number}
     */
    position() {
        return position(this);
    }

    /**
     * Add event listener for event to this node.
     * @variation 2
     * @param {any[]} [args] - selector to the child element multiple identical
     * listeners to the same event
     * @example
     * // attach click event to the child element matched by selector ".submit-btn"
     * element.subscribe(".submit-btn", "click", function (event) {
     *   console.log("On child element clicked");
     * })
     * @returns {UIElement}
     */
    @Deprecated(`Use element.addEventListener().`)
    subscribe(...args: unknown[]) {
        let target;
        let eventName;
        let handler;
        let allowDuplication;

        if (
            args.length === 2 ||
            (args.length === 3 && typeof args[2] === 'boolean')
        ) {
            target = '';
            eventName = args[0] as string;
            handler = args[1] as (event: Event) => void | null;
            allowDuplication = false;
        } else if (
            args.length === 4 ||
            (args.length === 3 && typeof args[2] === 'function')
        ) {
            target = args[0] as string;
            eventName = args[1] as string;
            handler = args[2] as (event: Event) => void | null;
            allowDuplication = (args[3] as boolean) || false;
        } else {
            throw new Error('Wrong arguments passed to subscribe function');
        }

        const element = !target ? this : this.querySelectorAll(target);
        if (!element || (element instanceof NodeList && !element.length)) {
            return this;
        }
        const subscriptions = this.getSubscriptions(<string>target, eventName);
        if (
            subscriptions.indexOf(handler as unknown as string) > -1 &&
            !allowDuplication
        ) {
            return this;
        }
        subscriptions.push(handler as unknown as string);

        [].forEach.call(
            element instanceof NodeList ? element : [element],
            (node: Node) => node.addEventListener(eventName, handler)
        );

        return this;
    }

    /**
     * Remove event listener from this node.
     * @deprecated use element.removeEventListener() instead.
     * @variation 2
     * @param {any[]} [args] - selector to the child element
     * @example
     * // detach the handler for click event from child element matched by selector
     * element.unsubscribe(".submit-btn", "click", handler)
     * @returns {UIElement} reference to itself
     */
    @Deprecated(`Use element.removeEventListener() instead`)
    unsubscribe(...args: unknown[]) {
        let target;
        let eventName;
        let handler;

        if (args.length === 2) {
            target = null;
            eventName = args[0];
            handler = args[1] as (event: Event) => void;
        } else if (args.length === 3) {
            target = args[0];
            eventName = args[1];
            handler = args[2] as (event: Event) => void;
        } else {
            throw new Error('Wrong arguments passed to unsubscribe function');
        }

        const element = !target ? this : this.querySelectorAll(<string>target);
        if (!element || (element instanceof NodeList && !element.length)) {
            return this;
        }
        const subscriptions = this.getSubscriptions(
            <string>target,
            <string>eventName
        );
        const index = subscriptions.indexOf(handler as (event: Event) => void);
        if (index > -1) {
            subscriptions.splice(index, 1);
        }

        [].forEach.call(
            element instanceof NodeList ? element : [element],
            (node: Node) => node.removeEventListener(<string>eventName, handler)
        );
        return this;
    }

    /**
     * Remove all event listeners from this node.
     * @variation 1
     * @param {string} param1 - event name
     * @example
     * // detach all handlers for click event from host element:
     * ```element.unsubscribeAll("click")```
     * @returns {UIElement}
     */
    /**
     * Remove all event listeners from this node.
     * @variation 2
     * @param {any[]} [args] - selector to the child element
     * @example
     * // detach all handlers for click event from child element matched by selector
     * element.unsubscribe(".submit-btn", "click", handler)
     * @returns {UIElement}
     */
    @Deprecated(`Use element.removeEventListener() instead`)
    unsubscribeAll(...args: unknown[]) {
        let target;
        let eventName;

        if (args.length === 1) {
            target = null;
            eventName = args[0];
        } else if (args.length === 2) {
            target = args[0];
            eventName = args[1];
        } else {
            throw new Error(
                'Wrong arguments passed to unsubscribeAll function'
            );
        }

        const element = !target ? this : this.querySelectorAll(<string>target);
        if (!element || (element instanceof NodeList && !element.length)) {
            return this;
        }
        const subscriptions = this.getSubscriptions(
            <string>target,
            <string>eventName
        );
        while (subscriptions.length) {
            const handler = subscriptions.shift();
            const collection =
                element instanceof NodeList ? element : [element];
            for (let i = 0; i < collection.length; i++) {
                collection[i].removeEventListener(
                    <string>eventName,
                    <EventHandler>handler
                );
            }
        }
        return this;
    }

    /**
     * Update class list of the node by given config.
     * @example
     * // adds -active class to the node classList and removes -awesome from the node classList
     * element.updateClassList({"-active": true, "-awesome": false})
     * @param {Record<string, boolean>} config - hash map where key is classname value is flag
     *                                            to add / remove the it
     * @returns {UIElement | HTMLElement}
     */
    updateClassList(config: Record<string, boolean>) {
        return updateClassList(this, config);
    }

    /**
     * Update class list of the node by reverting given config,
     * could be used for animations to rollback last applied class config.
     * @example
     * // adds -active class to the node classList and removes -awesome from the node classList
     * element.revertClassList({"-active": true, "-awesome": false})
     * @param {Record<string, boolean>} config - hash map where key is classname value is flag
     * to add / remove it.
     * @returns {UIElement | HTMLElement}
     */
    @Deprecated(`Use UI.revertClassList();`)
    revertClassList(config: Record<string, boolean>) {
        return revertClassList(this, config);
    }

    /**
     * @param {Record<string, boolean>} config - hash map where key is classname value is flag
     * @param {number} timeout
     * @returns {Promise<void>}
     */
    @Deprecated(`Use UI.runTransition()`)
    runTransition(config: Record<string, boolean>, timeout: number) {
        return runTransition(this, config, timeout);
    }

    /**
     * Recursively check attribute for validate() callback through UI parents
     * @param {string} name
     * @returns {IAttributeValidationHandler | undefined}
     * @protected
     */
    protected findValidateCallback(
        name: string
    ): AttributeValidationHandler | void {
        if (this.constructor.name === UIElement.name) {
            return;
        }

        const attr = (<typeof UIElement>this.constructor).props.attributes?.[
            name
        ];
        return <AttributeValidationHandler>(
            (attr
                ? (attr as IPropsAttribute).validate
                : UIElement.prototype.findValidateCallback.call(
                      Object.getPrototypeOf(this.constructor.prototype),
                      name
                  ))
        );
    }

    /**
     * Validate attribute's value if attribute has validate() callback
     * @param {string} name
     * @param {string} value
     */
    validateAttribute(name: string, value: string) {
        const validate = this.findValidateCallback(name);
        typeof validate === 'function' &&
            /** @type {IAttributeValidationHandler} */ validate(this, value);
    }

    /**
     * A lifecycle hook called when element's attribute has been changed.
     * attributeChangedCallback should not be used directly. Use observeAttributes instead.
     * @param {string} name - attribute name
     * @param {string} oldValue - previous value
     * @param {string} newValue - current value
     */
    protected attributeChangedCallback(
        name: string,
        oldValue: string,
        newValue: string
    ) {
        if (oldValue === newValue) {
            return;
        }
        if (
            UIElement.DEBUG_MODE &&
            this.hydrated &&
            this.hasAttribute(name) &&
            UIElement.propsToValidate?.includes(name)
        ) {
            this.validateAttribute(name, newValue);
        }
        const callback = this.observeAttributes.bind(
            this,
            name,
            oldValue,
            newValue
        );
        whenDOMReady(callback, true);
    }

    /**
     * Wrapper for attributeChangedCallback to use this dynamically on document state
     * and DOMContentLoaded. Should be used instead of attributeChangedCallback if needed.
     * @param {string} name - attribute name
     * @param {string} oldValue - previous value
     * @param {string} newValue - current value
     */
    observeAttributes(
        name: string,
        oldValue: AttributeType,
        newValue: string | null
    ) {}

    /**
     * Register plugin for class.
     * @param {string} key
     * @param {object} PluginConstructor
     * @param {boolean} [autoload]
     */
    static registerPlugin<T extends UIElement>(
        key: string,
        PluginConstructor: IComponentPlugin,
        autoload = false
    ) {
        if (!this.plugins) {
            this.plugins = {};
        }
        if (autoload) {
            if (!this.autoloadPlugins) {
                this.autoloadPlugins = {};
            }
            this.autoloadPlugins[key] = PluginConstructor;
        }
        this.plugins[key] = PluginConstructor;
        if (PluginConstructor.props) {
            this.defineAbstractElement(PluginConstructor.props);
        }
        if (isDOMReady()) {
            const tagName = this.is;
            const selector = autoload
                ? tagName
                : `${tagName}[plugins~="${key}"]`;
            document.querySelectorAll<T>(selector).forEach((node) => {
                const plugin = <IComponentPlugin>node.getPlugin(key);
                node.instancePlugins[key] = <IComponentPlugin>plugin;
                if (plugin?.render) {
                    plugin.render();
                }
                if (plugin?.hydrate) {
                    plugin.hydrate();
                }
            });
        }
    }

    /**
     * Un-register plugin for class.
     * @param {string} key
     */
    static unregisterPlugin(key: string) {
        delete this.plugins[key];
        delete this.autoloadPlugins[key];
    }

    /**
     * Gets the plugin by key.
     * @param {string} key
     * @returns {IComponentPlugin | null}
     */
    getPlugin(key: string) {
        if (!this.instancePlugins) {
            this.instancePlugins = {};
        }
        if (this.instancePlugins[key]) {
            return this.instancePlugins[key];
        }
        if (!(<typeof UIElement>this.constructor).plugins[key]) {
            return null;
        }
        const PluginConstructor = (<typeof UIElement>this.constructor).plugins[
            key
        ] as typeof IComponentPlugin;
        return new PluginConstructor(this);
    }

    /**
     * Gets all plugins for instance.
     * @returns {Record<string, string> | string}
     * @protected
     */
    protected getInstancePlugins() {
        if (this.instancePlugins) {
            return this.instancePlugins;
        }

        this.instancePlugins = {};
        const autoPlugins = (<typeof UIElement>this.constructor)
            .autoloadPlugins;
        if (autoPlugins) {
            Object.keys(autoPlugins).forEach((key) => {
                const PluginConstructor = (<typeof UIElement>this.constructor)
                    .autoloadPlugins[key] as typeof IComponentPlugin;
                this.instancePlugins[key] = new PluginConstructor(this);
            });
        }

        const pluginsStr = this.getAttribute('plugins');
        if (pluginsStr) {
            pluginsStr.split(/\s+/).forEach((key) => {
                if (!(<typeof UIElement>this.constructor).plugins[key]) {
                    console.warn(
                        'Plugin ' +
                            key +
                            ' is not registered for class ' +
                            this.constructor.name
                    );
                    return;
                }
                const PluginConstructor = (<typeof UIElement>this.constructor)
                    .plugins[key] as typeof IComponentPlugin;
                this.instancePlugins[key] = new PluginConstructor(this);
            });
        }
        return this.instancePlugins;
    }

    /**
     * @param {string} key
     * @param {number|Array<string>|Record<string, string>} [substitutions]
     * @returns {string}
     */
    getLabel(
        key: string,
        substitutions: number | string[] | Record<string, string>
    ) {
        return Labels.processLabel(
            (<typeof UIElement>this.constructor).labels,
            key,
            substitutions
        );
    }

    /**
     * Mutates the host element by given config or snapshot of another node
     * @param {IElementConfig | Node} newNode
     * @returns {HTMLElement}
     */
    rebuild(newNode: IElementConfig | Node) {
        return rebuild(this, newNode);
    }

    /**
     * @param {Array<I>} elements
     * @param {Function} [smoothRemove]
     * @param {Function} [smoothAppend]
     * @returns {HTMLElement}
     */
    rebuildChildren(
        elements: (IElementConfig | Node)[],
        smoothRemove?: (...args: unknown[]) => void,
        smoothAppend?: (...args: unknown[]) => void
    ) {
        return rebuildChildren(this, elements, smoothRemove, smoothAppend);
    }
    /**
     * Recursively pre-render Element tree.
     * @param {boolean} [recursive]
     */
    prerenderElementsTree(recursive?: boolean) {
        prerenderElementsTree(this, recursive);
    }

    /**
     * Call a specific lifecycle hook for component and for it's plugins
     * @param {("render"|"hydrate"|"disconnect"|"reconnect")} hookName
     */
    runLifecycleHook(hookName: LifeCycleHook) {
        if (typeof this[hookName] === 'function') {
            this[hookName]();
        }
        if (!(<typeof UIElement>this.constructor).plugins) {
            return;
        }
        const plugins = this.getInstancePlugins();
        Object.keys(plugins).forEach((key) => {
            if (typeof plugins[key][hookName] === 'function') {
                plugins[key][hookName]?.();
            }
        });
    }

    /**
     * A lifecycle hook called each time after the component's
     * element is inserted into the document.
     * Do not override the base hook directly.
     * The "render/hydrate/reconnect" methods should be used instead
     * @private
     */
    private connectedCallback() {
        const render = () => {
            try {
                if (!this.rendered) {
                    this.runLifecycleHook('render');
                    this.state = ComponentState.Rendered;
                }
                if (!this.hydrated) {
                    this.runLifecycleHook('hydrate');
                    this.state = ComponentState.Hydrated;
                    this.dispatchEvent(new CustomEvent('hydrated'));
                    if (UIElement.DEBUG_MODE) {
                        (<typeof UIElement>(
                            this.constructor
                        )).propsToValidate?.forEach((name) => {
                            if (this.hasAttribute(name)) {
                                this.validateAttribute(
                                    name,
                                    <string>this.getAttribute(name)
                                );
                            }
                        });
                    }
                } else {
                    this.runLifecycleHook('reconnect');
                }
            } catch (e) {
                console.log(this.constructor.name, this);
                console.error(e);
            }
        };
        whenDOMReady(render, !UIElement.ASYNC_MODE);
    }

    /**
     * A lifecycle hook called each time after the component's
     * element is removed from the document.
     * Do not override the base hook directly the "disconnect" method should be used instead
     * @private
     */
    private disconnectedCallback() {
        if (this.hydrated) {
            this.runLifecycleHook('disconnect');
        }
    }

    /**
     * This method should build initial markup, has to be overwritten in child class.
     * This method won't be called in runtime if the component was already pre-rendered.
     * Do not set local variables here.
     * Do not set any handlers here.
     */
    render() {}

    /**
     * This method should hydrate existing markup, has to be overwritten in child class.
     * This method won't be called in prerendering step only for runtime.
     * You should set local variables here
     * You should set handlers here
     */
    hydrate() {}

    /**
     * A lifecycle hook called when the component's
     * element is removed from the document.
     */
    disconnect() {}

    /**
     * A lifecycle hook called when after the hydrated component's
     * element is attached to the DOM tree
     */
    reconnect() {}
}

// FIXME GUI-3101 JSDoc issue
UIElement.defineElement('ui-element');
export { UIElement };

import type { AttributeType, IProps } from '../components/ui-element.types.js';
import { resolvePropertyDescriptor } from '../components/ui-element.helper.js';
import { nameConverter } from '../global/render-api.js';
import type { UIElement } from '../components/index.js';

/*
 * Defines an attribute for the component, accepts IPropsAttribute.
 */
export const Attribute = <T extends typeof UIElement>(type?: AttributeType) => {
    return (_: undefined, context: ClassFieldDecoratorContext) => {
        if (context.kind === 'field') {
            context.addInitializer(function (this: unknown) {
                const propertyKey = <string>context.name;
                const existingDescriptor = Object.getOwnPropertyDescriptor(
                    <InstanceType<T>>this,
                    propertyKey
                );

                const initialValue = existingDescriptor?.value;
                let adjustedType = type || typeof initialValue;

                // In JS Object(undefined|null) still returns ObjectConstructor.
                // Also, if type is undefined or null, it has ObjectConstructor
                // and will not be set as attribute, see updateElement() method
                // from Render API.
                if (adjustedType === undefined || adjustedType === null) {
                    adjustedType = <AttributeType>(
                        new Object(typeof adjustedType).constructor
                    );
                }

                const klass = (<InstanceType<T>>this).constructor as T;
                let staticPropsDescriptor = Object.getOwnPropertyDescriptor(
                    klass,
                    'props'
                );
                if (!staticPropsDescriptor?.set) {
                    staticPropsDescriptor = {
                        ...staticPropsDescriptor,
                        set(props: IProps) {
                            klass.props = props;
                        },
                        configurable: true,
                        writable: true,
                    };
                    Reflect.set(klass, 'props', staticPropsDescriptor);
                }
                if (!Object.hasOwn(klass.props, 'attributes')) {
                    Reflect.set(klass.props, 'attributes', {});
                }

                const attrName = nameConverter.propToAttribute(propertyKey);
                const descriptor = resolvePropertyDescriptor(
                    attrName,
                    adjustedType,
                    initialValue
                );
                Object.defineProperty(this, propertyKey, descriptor);
            });
        } else {
            throw new Error(
                '@Attribute decorator is only applied to the property'
            );
        }
    };
};

import { getDebugMode } from '../global/config.js';

/*
 * Mark field, accessor, class, and method as deprecated.
 * forced arguments force to print deprecated even if debug mode is not set. For
 * very urgent and critical deprecations.
 */
export const Deprecated = (alternative?: string, forced: boolean = false) => {
    return <This, Args extends [], Return>(
        target: This,
        context: DecoratorContext
    ) => {
        const name = context.name?.toString();
        const kind = context.kind;
        let msg = `${kind} ${name} is deprecated and will be removed in the future releases.`;
        if (getDebugMode() || forced) {
            if (alternative) {
                msg += ` ${alternative}`;
            }
            console.warn(msg);
        }
    };
};
